import { IconCloseCircleOutline, theme, IconProps } from '@coles/rocket'

export interface ErrorCrossCircleRocketProps extends IconProps {
  size?: number
  id?: string
}

export function ErrorCrossCircleRocket(props: ErrorCrossCircleRocketProps) {
  const { size, ...rest } = props

  const height = size ? `${size}px` : '74px'
  const width = size ? `${size}px` : '74px'

  return (
    <IconCloseCircleOutline
      width={width}
      height={height}
      data-testid='error-cross-circle-rocket'
      color={theme.colorBackground.brand.hover}
      {...rest}
    />
  )
}
