import { Button, ButtonProps } from '@coles/rocket'

export interface ThemedButtonRocketProps
  extends Omit<ButtonProps, 'variant' | 'size'> {
  size?: ButtonProps['size']
  variant?: ButtonProps['variant']
  noLinkHover?: boolean
}

export const ThemedButtonRocket = (props: ThemedButtonRocketProps) => {
  const { variant, size, ...rest } = props
  return (
    <Button {...rest} size={size || 'large'} variant={variant || 'primary'} />
  )
}
